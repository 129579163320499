.whowe1-container {
  text-align: left;
  width: 90%;
  margin: 0 auto;
}



.main-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.whoweare-section {
  padding: 18px;
}

.whoweare-section h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.02em;
  color: #E00006;
  
}

.whowepara-section {
  border-left: 3px solid #e10006;
  margin-top: 3%;
}

.whoweare-para, .secondary-para, .full-width-para {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  text-align: justify;
  color: black;
  margin-bottom: 18px;
}
.whoweare-para{
  padding-left: 3%;
}
.secondary-section {
  display: flex;
  flex-direction: column; /* Change to column */
  align-items: center; /* Align items to center */
  width: 100%;
}

.side-images {
  display: flex;
  justify-content: space-between; /* Add space between images */
  width: 90%;
  margin: 0 auto;
 
}

.side-image {
  width: 48%; /* Adjust width of images */
  height: auto;
}

.secondary-para {
  padding-top: 30px; /* Add margin to separate from images */
  width: 90%;
  margin: 0 auto;
}

.full-width-para {
  width: 90%;
 
  margin: 0 auto;
  margin-top: 18px; /* Add margin to separate from paragraph */
  margin-bottom: 40px;
}

.full-width-image {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

/* Mobile styles */
@media (max-width: 768px) {
  .whowe1-container {
    width: 95%;
  }
  .whoweare-section {
    padding: 10px;
  }

 

  .main-image {
    height: auto;
    max-width: 100%;
  }

  .whoweare-section h2 {
    font-size: 28px;
    line-height: 35px;
  }

  .whoweare-para, .secondary-para, .full-width-para {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 15px;
  }

  .side-images {
    flex-direction: column; /* Change to column for mobile */
  }

  .side-image {
    width: 100%;
    margin-bottom: 15px;
  }
  .full-width-image{
    width: 100%;
  }
}



/* animation style */


@keyframes zoomer {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.zoomer-move {
  animation: zoomer 1s infinite alternate;
}

