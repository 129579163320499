.domainMain-container {
  position: relative;
  width: 90%;
  margin: 0 auto;
}
.domainMainsub{
  margin-top: 1px !important;
}

.domainMain-banner {
  width: 100%;
 
}

.domainMain-bannerImg {
  width: 100%;
  
}




.domainMain-gridItem img {
 width: auto;
}







.view-all-button-container {
  display: flex;
  justify-content: flex-end; 
 
}

.view-all-button {
  font-family: 'Inter';
  font-size: 21px;
  font-weight: 400;
  line-height: 47.58px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #E10006;
  background-color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
}

.view-all-icon{
  margin-left: 15px;
  font-size: 30px;
}
.view-all-button:hover {
  color: black;
}

.vab-1{
  margin-right: 8px;
}

/* Adjust margin for mobile */
@media (max-width: 768px) {
    .view-all-button {
        margin-top: 20px; 
    }
    .view-all-button {
    margin: 0 auto;
    }
}





.domainMain-gridItem .domainMain-info {
  background: linear-gradient(180deg, #E10006 7.02%, #7B0003 100%);  

}
.domainMain-gridItem:hover .domainMain-info {
  background: rgb(255, 255, 255);
  color: red;
 background-color: #ffffff;
 box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Adjust the shadow as needed */
 border:2px solid  #eeeeee;

}


