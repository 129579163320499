.clients-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 0px;
}
.client-section{
  border-left: 3px solid #e10006;
}

.clients-image {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.clients-image img {
  width: 60%;
  height: auto;
}

.clients-para {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.02em;
  text-align: justify;
  color: #676060;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}



.right-align-view-all{
  width: 89%;
  margin: 0 auto;
}










.zanda-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 70px;
  width: 100%;
  margin-top: 5%;
}

.zanda-box {
  display: flex;
  align-items: center; /* Center aligns both image and text vertically */
  justify-content: center; /* Center aligns both image and text horizontally */
  background-color: #eeeeee;
  padding: 10px;
 
  
}

.zanda-image {
  width: 25%;
  width: 25%; /* Limit the width to a fixed percentage */
  flex-shrink: 0; /* Prevent shrinking of the image */
  border-radius: 50%;
 margin-left: 15%;
}

.zanda-name {
  flex-grow: 1; /* Allow the text to grow and take available space */
  flex-shrink: 0; /* Prevent shrinking of the text */
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px; /* Adjust line-height */
  letter-spacing: 0.02em;
  color: black;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  text-align: center;
}
.zanda-box:hover {
  background: linear-gradient(180deg, #E10006 1.6%, #820003 73.1%, #7B0003 100%);
  color: white; /* Change text color to white for better contrast */
  color: white;
}
.zanda-box:hover .zanda-name {
  color:white; /* Change text color to red when .zanda-box is hovered */
}



.view-all-button img {
  margin-left: 10px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .zanda-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
   
  }
  .clients-container{
   
    width: 100%;
  }
  .clients-image img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  .zanda-container {
    grid-template-columns: 1fr;
 
  }

  .clients-para {
    font-size: 18px;
  }

  .zanda-name {
    font-size: 20px;
    line-height: 36.04px;
  }
}

@media (min-width:776px) and (max-width:1240px){
  .zanda-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
 
    width: 100%;
    margin-top: 5%;
  }
  .zandasubpage{
    gap: 30px 100px;
  }
}



