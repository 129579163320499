/* Default styles for PC */
.whowe2-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.section-image {
    align-items: left;
    margin-right: 15px;
}

.section-image img {
    width: 70%;
    height: 231px;
    object-fit: contain;
    padding: 0px;
}

.section-content {
    width: 100%;
    padding: 20px;
    border-left: 3px solid #e10006;
    margin-bottom: 50px;
}

.section-para {
   
    font-size: 18px;
    font-weight: 400;
   
    text-align: justify;
   
    font-family: Inter;
font-size: 18px;
font-weight: 400;
line-height: 48px;
text-align: left;
color: rgba(59, 59, 59, 1);


}
.testimonial-content{
    width: 80%;
    margin: 0 auto;
}

.management-section {
    display: flex;
    justify-content: space-between;
    
   
    padding: 20px;
    align-items: center;
    background: #fffcfc;
    margin-bottom: 40px;
   border-radius: 8px;
    background-color: rgba(238, 238, 238, 1);

}
.management-section:hover {
    background-color: white;
    border: 2px solid rgba(103, 96, 96, 1); 
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3); /* Add box shadow on hover */
}

.management-image {
    
    background: #FFFFFF;
    border-radius: 50%;
}

.management-content {
    flex-grow: 1;
    padding-left: 50px;
}

.management-name {
   
  
    letter-spacing: 0.02em;
    text-align: left;
    font-family: Inter;
font-size: 30px;
font-weight: 900;
line-height: 71.37px;
letter-spacing: 0.02em;

color: rgba(59, 59, 59, 1);

    
}
.management-section:hover .management-name {
    background: linear-gradient(177.11deg, #E10006 27.2%, #7A0003 97.59%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.management-title {
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-weight: 400;
   
    letter-spacing: 0.02em;
    text-align: left;
   
    display: inline-block;
    width: 400px;
    color: rgba(59, 59, 59, 1);
    
}

.management-para {
    font-family: 'Inter';
    font-size: 17px;
    font-weight: 400;
    line-height: 30.65px;
    letter-spacing: 0.02em;
    text-align: justify;
    color: rgba(0, 0, 0, 1);
    ;
}
.divider-line {
    width: 100%;
    height: 1px;
    
    color: #e10006; /* Or any color you prefer */
    margin: 10px 0; /* Adjust margin as needed */
    opacity: 500;
    border: #e10006 2px solid;
  }
  

/* Mobile styles using media queries */
@media (max-width: 768px) {
    .whowe2-container {
        width: 100%;
        padding: 20px; /* Full width for mobile */
    }
    .section-content p{
        padding-left: 0px;
        padding: 0px;
    }
    .management-content {
        
        padding-left: 0px;
    }
    .testimonial-content{
        width: 100%;
        margin-top: 5%;
       
    }
    .section-para {
        font-size: 16px;

    }

    .section-image {
        margin-right: 0; /* Remove right margin */
    }

    .section-image img {
        width: 100%; /* Full width for mobile */
        height: auto; /* Auto height */
    }

    .management-section {
        flex-direction: column; /* Stack items vertically on mobile */
        align-items: center; /* Center align content */
        width: 100%;
        padding: 20px;
    }
    .management-image img{
        width: 100%;
        height: 100%;
    }
    .management-image {
        width: 60%;
        height: 100%;
    }

    


    .management-content {
        text-align: center;
    }

    .management-name {
        font-size: 28px; /* Adjust font size for mobile */
        line-height: 36px; /* Adjust line-height for mobile */
        text-align: center;
        
    }

    .management-title {
        font-size: 20px; /* Adjust font size for mobile */
        line-height: 28px; /* Adjust line-height for mobile */
        width: auto; /* Remove fixed width */
        text-align: center;
        width: 100%;
        padding: 0px;
        
    }

    .management-para {
        font-size: 16px; /* Adjust font size for mobile */
        line-height: 24px; /* Adjust line-height for mobile */
    }
}


@media (min-width:768px) and (max-width:1213px){
   
   

    .management-section {
        flex-direction: column; /* Stack items vertically on mobile */
        align-items: center; /* Center align content */
        width: 100%;
        padding: 20px;
    }
    .management-image img{
        width: 100%;
        height: 100%;
    }
    .management-image {
        width: 60%;
        height: 100%;
    }
    .management-content {
        text-align: center;
    }

    .management-name {
        font-size: 28px; /* Adjust font size for mobile */
        line-height: 36px; /* Adjust line-height for mobile */
        text-align: center;
        
    }

    .management-title {
        font-size: 20px; /* Adjust font size for mobile */
        line-height: 28px; /* Adjust line-height for mobile */
        width: auto; /* Remove fixed width */
        text-align: center;
        width: 100%;
        padding: 0px;
        
    }
  }




  /* animation style */

  @keyframes zoomer {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.05);
    }
  }
  
  .zoomer-mover {
    animation: zoomer 1s infinite alternate;
  }
  