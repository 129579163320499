/* Default styles */

.unit1-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 100px 100px 0px 100px;
}

.left-content {

  transition: opacity 0.5s ease-in-out;
  padding-right: 20px;
}

.company-info {
  font-family: Inter;
  font-size: 20px;
  font-weight: 300;
  border-left: 3px solid #e11006;
  padding-left: 40px;
  text-align: justify;
  line-height: 3.6rem;
}

.about-company-button {
  
  
  gap: 10px;
 padding: 0px 12px;
  border-radius: 4px 0 0 0;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  font-family: Moul;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 43.36px;
  text-align: left;
  background: #E10006;
  color: white;
  margin-top: 30px;
  border: none;
  border-radius: 5px;
  
 
}


.span-red{
  color: red;
}
.span-dark{
  font-weight: bold;
}

.button-class {
  margin-top: 50px;
}


.company-image {
 
  height: 423px;
  border-radius:6px;
  padding: 0px 0px 50px 50px;
}

.about-company-button .rightcircle-icon {
  width: 40px;
  height: 50px;
  margin-left:12px;
}
.about-company-button:hover {
 
  background-color: white;
  color: #E10006;
  
}


/* Media Queries */

@media (max-width: 1200px) {
  .unit1-container {
    padding: 80px;
  }

  .left-content {
    padding-right: 15px;
  }

  .company-info {
    font-size: 20px;
    line-height: 40px;
  }

  
  .company-image,
  .right-content {
    width: 589px;
    padding: 0px 0px 30px 30px;
  }
}

@media (max-width: 1060px) {
  .unit1-container {
    flex-direction: column;
    padding: 20px 20px;
  }

  .left-content,
  .right-content {
    width: 100%;
  }

  .company-image,
  .right-content {
    padding: 0;
  }

  .company-image {
    width: 100%;
    height: auto;
    border-radius: 0;
    margin-top: 25px;
  }

 
  .company-info{
    padding-left: 15px;
  }
}


@media (min-width:776px) and (max-width:1240px){
  /* .about-company-button {
    width: 30%;
    margin-top: 40px;
  } */
  
}



@media (max-width: 768px) {
  
  .about-company-button {
  
  
   
    font-size: 1rem;
   
    
   
  }
  .company-info{
    font-size: 18px;
  }
}



