.award-container{
  
}
.full-width {
  width: 100%;
}

.center-image {
  display: block;
  margin: 0 auto;
}

.award-title {
  font-family: 'Inter';
  font-size: 1.6em; /* 32px */
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 20px;
  color: #e10006;
  padding-left: 6.5%;
  margin-top: 3%;
}

.title.red {
  color: red;
  padding-left: 6.5%;
  font-size:1.6em;
  margin-bottom: 20px;
}

.award-container {
  position: relative;
}

.awards-content {

 
  margin: 0 auto;
}

/* Award section styles */
.award-section {
  display: flex;
  justify-content: space-between;
  background-color: #eeeeee;
  padding: 20px;
  margin-bottom: 20px;
}

.award-left img {
  width: 70%; /* Adjust as needed */
  background-color: white;
}

.award-right {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 auto;
}

.award-right img {
  max-width: 100%; /* Adjust as needed */
  
}
.full-width-2{
  height: auto;
  width: 100%;
}

.full-width-2:hover{
 
 
}

/* Gallery grid styles */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; /* Adjust the gap between grid boxes */
  background-color: #ffffff;
  padding: 10px;
  width: 80%;
  margin: 0 auto;
}

.grid-box {
  width: 70%;
  margin: 0 auto;
  height: 80%;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center; 
  /* border-radius: 30px; */
}

.grid-image {
  max-width: 90%; /* Adjust the max width of the image */
  max-height: 90%; /* Adjust the max height of the image */
  object-fit: contain;
}

/* Hover effect for the grid box */
.grid-box:hover {
  background-color: #dddddd; /* Change background color on hover */
}

/* Hover effect for the image */
.grid-image:hover {
  transform: scale(1.1); /* Increase size of the image on hover */
}

/* Bottom image */
/* .content img:last-child {
  margin-top: 20px;
} */




@media (max-width: 768px) {  
  .award-container{
    
  }
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    
  }

  .grid-box {
    width: 90%;
  }

  .grid-image {
    max-width: 90%;
    max-height: 90%;
  }
}

@media (max-width: 480px) {  
  .grid-container {
    grid-template-columns: repeat(2 1fr);
  }

  .grid-box {
    width: 80%;
    height: auto;
  }

  .grid-image {
    max-width: 90%;
    max-height: 90%;
  }
  .full-width-2{
    height: 50%;
  }
  .award-title{
    font-size: 20px;
  }
  .title.red{
    font-size: 20px;
  }
}


@media (max-width: 768px) {
  .award-container {
    max-width: 100%; /* Adjust the max-width as needed */
    overflow-x: hidden; /* Hide horizontal overflow */
  }
}








/* .full-width-2 {
  animation: filterCycle 20s linear infinite; 
}

@keyframes filterCycle {
  0% {
    filter: grayscale(0) contrast(100%) brightness(100%) sepia(0) hue-rotate(0deg); 
  }
  20% {
    filter: grayscale(100%) contrast(100%) brightness(100%) sepia(0) hue-rotate(0deg); 
  }
  40% {
    filter: grayscale(0) contrast(150%) brightness(100%) sepia(0) hue-rotate(0deg); 
  }
  60% {
    filter: grayscale(0) contrast(100%) brightness(120%) sepia(0) hue-rotate(0deg); 
  }
  80% {
    filter: grayscale(0) contrast(100%) brightness(100%) sepia(100%) hue-rotate(0deg); 
  }
  100% {
    filter: grayscale(0) contrast(100%) brightness(100%) sepia(0) hue-rotate(180deg); 
  }
} */
.full-width-2 {
  width: 100%;
  animation: zoomAnimation 5s infinite alternate; /* Adjust duration and timing as needed */
}

@keyframes zoomAnimation {
  0% {
    transform: scale(1) translateZ(0);
  }
  50% {
    transform: scale(1) translateZ(50px); /* Adjust the distance as needed */
  }
  100% {
    transform: scale(1) translateZ(0);
  }
}
