.client1-container {
    width: 100%;
    margin: 0 auto;
    margin-top: 8.5%;
    background-color: rgb(226, 228, 230);
}

.firstimage-cnt{
    width: 100%;
    background-color: white;
    margin: 0 auto;
   
   
}

.firstimage-cnt img{
    width: 50%;
    padding-left: 6%;


}

.first-image1 {
    width: 100%;
    height: auto;
    
}
.first-image1

.flag-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flag-row {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  

}

.flag {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin-right: 50px;
}

.flag-image1 {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0px 0px;
}

.flag-name {
    text-align: center;
    font-weight: bold;
    background: linear-gradient(179.31deg, #E00006 62.71%, #7A0003 99.41%);
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 36.7px;
    letter-spacing: 0.02em;
    color: white;
    border-radius: 0px 0px 10px 10px;
    padding: 5px;
}

.flag-name:hover {
    background-color: white; 
    background-image: none; 
    color: linear-gradient(179.31deg, #E00006 62.71%, #7A0003 99.41%); 
    color: rgb(5, 5, 5);
    transition: background 0.3s, color 0.3s, box-shadow 0.3s; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); 
}

@media (min-width: 769px) and (max-width: 1200px) {
    .client1-container {
        margin-top: 10%;
      }
    .flag-container {
        padding-left: 1%;
    }
    .flag-6,
    .flag-7,
    .flag-8,
    .flag-9,
    .flag-15,
    .flag-16 {
        margin-top: 0;
    }

    .flag-6,
    .flag-7,
    .flag-8,
    .flag-9 {
       
    }

    .flag-15,
    .flag-16 {
        
    }
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
    .client1-container {
      margin-top: 30%;
    }
    .firstimage-cnt img{
        width: 100%;
        padding-left: 6%;
    
    
    }
    
    .flag-row {
        flex-wrap: wrap;
        justify-content: center;
    }

    .flag {
        width: 45%; /* Two flags per row */
        margin: 0 2.5%; /* Adjusting for the margin */
        margin-bottom: 20px;
    }

    .flag-6,
    .flag-7,
    .flag-8,
    .flag-9,
    .flag-15,
    .flag-16 {
        margin-top: 0;
    }

    .flag-6,
    .flag-7,
    .flag-8,
    .flag-9 {
        margin-left: 2.5%;
    }

    .flag-15,
    .flag-16 {
       
    }
}
