.mini-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #BB0106 8.55%, #7A0104 95.34%);
    padding: 10px 0;
}

.footer-logo {
   width: 120px;
    height: auto;
    margin-right: 10%;
}

.footer-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-link {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 700;
    line-height: 36.7px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #fff;
    text-decoration: none;
    margin: 0 20px;
    position: relative;
}

.footer-link::before,
.footer-link::after {
    content: '|';
    color: #fff;
    position: absolute;
    right: -20px;
    font-size: 20px;
    font-weight: 100;
}

.footer-link::before {
    content: '';
    left: -15px;
}

/* Remove the last partition line and add margin to the last link */
.footer-link:last-child::after {
    content: none;
    margin-right: 0;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .footer-nav {
        flex-direction: column;
        align-items: flex-start;
    }
    .mini-footer-link::after{
   
        display: none;
    
    }
    

    .footer-link {
        margin: 10px 0;
        position: relative;
        font-size: 15px;
        text-align: center;
    }
   
.footer-link::after {
    content: '|';
    color: #fff;
    position: absolute;
   
    font-size: 20px;
    font-weight: 100;
}


    

    .footer-link::before {
        left: 0;
    }

    .footer-link::after {
        right: -8px;
    }
    .footer-link::before,
.footer-link::after {
   
}

}

