.domainMain-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 10%;
}

.domainMain-banner {
  width: 100%;
}

.domainMain-bannerImg {
  width: 100%;
}

.domainMain-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.domainMain-gridItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensuring content doesn't overflow */
  transition: transform 0.5s ease;
 

}

.domainMain-img {
  width: 100% !important;
  height: auto; 
  object-fit: contain; 
  /* Adding transition for transform property */
  filter: brightness(70%);
}




.domainMain-gridItem:hover .domainMain-img {
 
  filter: brightness(110%);
 

}





.domainMain-gridItem:hover {
  transform: translateY(-15px);
}
.domainMain-info {
  width: 100%;
  max-width: 100%;
  height: 70px;
  color: white;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, font-size 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}



/* Responsive styles */
@media (max-width: 768px) {
  .domainMain-container {
    margin-top: 25%;
  }

  .domainMain-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .domainMain-info {
    font-size: 18px;
   
    height: 50px;
  }
}

@media (min-width: 786px) and (max-width: 992px) {
  .domainMain-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  
}

@media (min-width: 993px) {
  .domainMain-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
