/* Client2Comp.css */

.client2-container {
    width: 100%;
    margin-top: 120px;
  }
  
  .first-image-container {
    width: 100%;
  }
  
  .first-image {
    width: 70%;
   padding-left: 6%;
  }
  
  .section-container {
    background-color: #f2f2f2; /* Setting background color for the section */
    padding: 20px; /* Adding padding around the section */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-aligning the items horizontally */
  }
  
  .map-image-container {
    width: 70%;
    margin-bottom: 20px; /* Adding margin at the bottom of the map image container */
  }
  
  .world-map-image {
    width: 100%;
  }
  
  .para-container {
    width: 86%;
    margin-bottom: 10px; /* Adding margin at the bottom of the paragraph container */
    border-left: 3px solid #e10006;
    margin: 0 auto;
  }
  
  .para {
    padding-left: 2.5%;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    line-height: 36.7px;
    letter-spacing: 0.02em;
    text-align: left;
    color: black;
    margin-top: 10px; /* Adding some space above the paragraph */
    margin-bottom: 10px;
    font-family: Inter;
font-size: 18px;
font-weight: 400;
line-height: 36.7px;
letter-spacing: 0.02em;
text-align: left;
margin-bottom: 40px;
  }
  
  .zandasubpage{
    width: 86%;
    gap: 60px;
   
  
    
  }
  .zandasubbox{
    padding: 5px;
    background-color: #d7d3d3;
    
  }

  
  .zandasubbox img{
    width: 75px;
    height: 70px;
    
  }
  .zandasubname{
    font-size: 20px;
  }


  
  @media (max-width: 768px) {
    .zandasubpage{
       
        gap: 20px;
        padding: 0px;
       }
       .client2-container{
        
       }
       .para{
        padding-left: 20px;
        font-size: 16px;
       }
       .first-image {
        width: 100%;
       padding-left: 6%;
      }
      .map-image-container {
        width: 100%;
        
    }

  }



  @media (min-width:768px) and (max-width:1240px) {
    .client2-container{
      
     }
  }


 