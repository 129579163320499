.why-hts-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin: 0 auto;
    padding: 0px 20px;
  }
  
  .image-container {
    width: 100%; /* Adjusted to make it responsive */
    max-width: 800px; /* Maximum width for desktop */
  }
  
  .image-container svg {
    width: 100%;
    height: auto;
  }
  
  .content-container {
    font-family: 'Inter';
    font-size: 21px;
    font-weight: 400;
    text-align: left;
    color: #676060;
    word-spacing: 3px;
  }
  
  .content-section {
    padding-left: 20px;
    border-left: 3px solid #e11006;
}
  
  @media (max-width: 768px) { /* Media query for mobile screens */
    .why-hts-container {
      width: 100%; 
      padding: 0px 10px; 
    }
  
    .image-container {
      width: 100%; 
      max-width: none; 
    }
  
    .content-container {
      font-size: 18px; 
    }
    .content-section{
        margin-left: 12px;
    }
  }
  