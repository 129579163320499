.carousel-container {
    width: 100% !important;
    height: auto !important;
   
   
  }
  
  .main-carousel-image {
    width: 100%;
    height: auto; /* Set height relative to viewport height */
    object-fit: cover;
  }
  
  .carousel-caption {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 2vw; /* Set padding relative to viewport width */
    color: #fff;
  }
  
  .carousel-container .carousel-indicators button {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 1vw; /* Dot width relative to viewport width */
    height: 1vw; /* Dot height relative to viewport width */
    margin: 0 1vw; /* Dot margin relative to viewport width */
    text-indent: -999px;
    cursor: pointer;
    background-color: transparent;
    border: 0.2vw solid #fff; /* Border for the dots relative to viewport width */
    border-radius: 50%;
  }
  
  .carousel-container .carousel-indicators button.active {
    background-color: #e01010;
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: none !important;
  }
  .carousel-indicators {
     
    margin-bottom: 0.5rem !important;
    
}
  
  
  /* Media Queries for responsiveness */
  @media (max-width: 786px) {
    
  
    .main-carousel-image {
     
    }
  
    .carousel-caption {
      padding: 3vw;
    }
  
    .carousel-container .carousel-indicators button {
      width: 2vw;
      height: 2vw;
      margin: 0 0.5vw;
      border: 0.3vw solid #fff;
    }
  }
  
  @media (max-width: 576px) {
    .carousel-container {
      height:auto  !important;
      
      
    }
  
    .main-carousel-image {
     
    }
  
    .carousel-caption {
      padding: 4vw;
    }
  
    .carousel-indicators {
     
      margin-bottom: 0.5rem !important;
      
  }
    
  }
  
  @media (min-width:786px) and (max-width:1230px){
  
    
  }










  .carousel-container .carousel-item:first-child .learn-more-button {
    /* Your styles for the "Learn More" button on the first slide */
    position: absolute;
    top: 71%; /* Adjust as needed */
    left: 8%; /* Adjust as needed */
    transform: translate(-50%, -50%);
}

.carousel-container .carousel-item:nth-child(2) .learn-more-button {
  /* Your styles for the "Learn More" button on the second slide */
  position: absolute;
  bottom: 8%; /* Adjust as needed */
  left: 86%; /* Adjust as needed */
  transform: translate(0, 0);
}


.carousel-caption .learn-more-button {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  background-color: #E10006;
  color: white;
  border: 1px solid rgba(224, 0, 6, 1);
  padding: 10px 20px; /* Adjust padding as needed */
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: auto; /* Set width to auto */
  height: auto; /* Set height to auto */
  white-space: nowrap; /* Prevent text wrapping */

  display: inline-block; /* Ensure button takes up only necessary space */
}


.carousel-caption .learn-more-button:hover {
  background-color: #fefafa; /* Darker shade of primary color on hover */
  color: #e01010;
  border: 1px solid #E10006;
}

.carousel-caption .learn-more-button .learn-more-icon {
  margin-left: 5px; /* Adjust icon position as needed */
}


/* Set the icon size for all slides */
.carousel-caption .learn-more-button .learn-more-icon {
  width: 30px; /* Set icon width */
  height: 30px; /* Set icon height */
  font-size: 30px; /* Set icon font size */
  margin-right: 5px; /* Adjust spacing between icon and text */
}

.carousel-caption .learn-more-button:hover,
.carousel-caption .learn-more-button:focus {
  background-color: #fefafa !important; /* Darker shade of primary color on hover or focus */
  color: #e01010 !important;
  border: 1px solid #E10006 !important;
}





@media (max-width: 700px) {
  .carousel-caption .learn-more-button {
 
    font-size: 12px;
    padding: 3px 2px;
    
   
  
  }
  .carousel-container .carousel-item:first-child .learn-more-button {
   display: none;
  }
  
  .carousel-container .carousel-item:nth-child(2) .learn-more-button {
  display: none;
  }
  
}


@media (min-width: 700px) and (max-width: 1000px)  {
  .carousel-container .carousel-item:first-child .learn-more-button {
   
    position: absolute;
    top: 75%; /* Adjust as needed */
    left: 8%; /* Adjust as needed */
    transform: translate(-50%, -50%);
}

  .carousel-container .carousel-item:nth-child(2) .learn-more-button {
    bottom:-3%; 
    left: 78%;
    white-space: nowrap; 
  }
  .carousel-caption .learn-more-button {
    padding: 5px 10px ;
 
}

.carousel-caption .learn-more-button .learn-more-icon {
  width: 20px; /* Set icon width */
  height: 20px; /* Set icon height */
  margin-right: 0px;
}

}


@media (min-width: 700px) and (max-width: 1300px){
  .carousel-container .carousel-item:nth-child(2) .learn-more-button {
    bottom:3%; 
    left: 78%;
    white-space: nowrap; 
  }
}



