.fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 0.5s;
  }
  
  .content {
    margin-top: 100px; /* Adjust the value as needed to create space below the fixed header */
  }
  