.contact-us2-container {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 50px;
  }
  
  .contact2-left-section,
  .contact2-right-section {
    flex: 1;
    
  }
  .contact2-right-section {
    position: relative;
}

.contact2-right-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 90%;
    background-color: #e10006;
}

  
  .address-text,
  .email-text {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    line-height: 39.12px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #3B3B3B;


   

    
  }

  .address-text span{
    font-family: Inter;
font-size: 20px;
font-weight: 700;
line-height: 39.12px;
letter-spacing: 0.02em;
text-align: center;

  }
  .email-text a {
    /* text-decoration: none; */
    color: inherit;
    transition: color 0.3s; /* Smooth transition for color change */
}

.email-text a:hover {
  color: rgb(255, 0, 21);
}

  
  .social-icons {
    display: flex;
    justify-content: space-around;
  }
  
  .social-icons img {
    width: 40px;
    height: 40px;
    margin: 0 10px;
    transition: transform 0.3s;
  }
  .social-icons img:hover {
    transform: rotate(360deg); 
}



  
  /* Responsive styles */
  @media (max-width: 1024px) {
    .contact-us2-container {
      flex-direction: column;
      align-items: center;
    }

  }
  
  /* Responsive styles for mobile devices */
@media (max-width: 768px) {
    .contact-us2-container {
      padding: 20px;
      flex-direction: column;
      width: 100%;
    }
  
    .contact2-left-section,
    .contact2-right-section {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .contact2-right-section::before {
      height: 3px;
      width: 60%;
      left: 20%;
    }
  
    .address-text,
    .email-text {
      font-size: 20px;
      line-height: 120%;
      margin-bottom: 20px;


    }
    .address-text{
        margin-top: 5%;
    }
  
    .social-icons img {
      width: 30px;
      height: 30px;
      margin: 0 8px;
    }
    .address-text{
        padding-top: 5%;
    }
    .contact2-right-section::before {
        height: 3px;
        width: 60%;
        
        top: 10px; /* Adjusting the top position to align with the text */
      }
      .contact2-right-section::before {
        width: calc(100% - 10px); /* Adjust width to consider padding */
        left:0px; /* Adjust left to consider padding */
      }
    
  }

  
  
  @media (min-width:776px) and (max-width:992px){
    .address-text,
    .email-text {
      padding-left: 1%;

    }
    
  }