.mv-solution-wrapper {
  width: 100%;
  margin-top: 120px;
}

.search-bar {
  display: flex;
  align-items: center;
  width: 50%;
  height: 44px;
  padding: 0px 16px;
  gap: 0px;
  border-radius: 4px;
  border: 1px solid #A4A4A4;
  opacity: 1;
  margin: 0 auto;
}
[data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}

.search-icon {
  width: 25px;
  height: 25px;
}

.search-input {
  flex: 1;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 400;
  line-height: 24.2px;
  letter-spacing: 0.02em;
  text-align: left;
  border: none;
  outline: none;
  margin-left: 2%;
}

.product-section {
  margin-top: 20px;
}

.product-title {
  font-family: "Inter";
  font-size: 22px;
  font-weight: 500;
  line-height: 38.73px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #e10006;
  margin-left: 6.5%;
  margin-top: 2%;
}

.product-title span {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 29.05px;
  letter-spacing: 0.02em;
  text-align: left;
}

.product-image {
  width: 100%;
  height: 500px;
  background-color: #6a6666;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;
}

.product-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: auto;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  transition: border 0.3s ease;
}

.product-box img {
  width: 100%;
  object-fit: contain;
 
}

.product-box:hover {
  border: 1px solid #6b6a6a;
}

.product-box-image {
  width: 90%;
  height: 400px;
  border: none;
}

.product-description {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 29.05px;
  letter-spacing: 0.02em;
  text-align: center;
  color: black;
  margin-bottom: 10px;
 
}

.explore-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52.01px;
  padding: 4px 22px;
  margin-top: 10px;
  border: 1px solid #000000;
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: Inter;
  font-size: 22px;
  font-weight: 400;
  line-height: 29.05px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #676060;
}
.explore-link {
  text-decoration: none;
}

.product-box:hover .explore-button {
  background-color: #e10006;
  color: white;
}


.button-icon {
  font-size: 20px;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .mv-solution-wrapper {
    
  }
  
  .search-bar {
    width: 80%;
    margin: 0 auto;
  }
  
  .product-title {
    font-size: 18px;
    font-weight: 500;
    padding-left: 1%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  
  .product-title span {
    font-size: 15px;
    font-weight: 400;
  }
  
  .product-image {
    height: 150px;
  }
  
  .product-box {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
  
  .product-box-image {
    width: 100%;
    height: 60%;
  }
  
  .product-description {
    font-size: 16px;
  }
  
  .explore-button {
    width: 100%;
  }
  
  .search-input {
    font-size: 17px;
    margin-left: 2%;
    width: 50%;
  }
}

@media (min-width:768px) and (max-width:1200px){
  .mv-solution-wrapper {
    margin-top: 12%;
  }
  
  .product-list {
    width: 80%;
    margin: 0 auto;
  }
  
  .product-title {
    text-align: center;
  }
  .product-box-image {
   
    height: auto;
   
  }
}






/* Product > LV Solution (total 10 Products) */


