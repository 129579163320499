.ProductDetailpage {
  margin-top: 120px;
}

.product-detail-title {
  font-family: 'Inter';
  font-size: 25px;
  font-weight: 500;
  line-height: 38.73px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #e10006;
  padding-left: 6.7%;
}

.carousel {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 70%;
}

.carousel-icon {
  width: 40px;
  height: 40px;
  background: #e10006;
  border-radius: 50%;
  border: 5px solid #e10006;
  user-select: none; 
  
}

.carousel-icon:hover {
  background-color: #aba9a9;
  border: 5px solid #afacac;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
}

.product-info {
  width: 90%;
  height: 500px;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
}

.product-info img {
  width: 90%;
  height: 380px;
}

.product-description-class {
  font-family: 'Inter';
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.02em;
  text-align: center;
  color: black;
  margin-top: 20px;
}

.product-detail-section {
  display: flex;
  flex-wrap: wrap; /* Added to make it wrap to the next line on smaller screens */
  justify-content: space-between; 
  align-items: flex-start; 
  width: 87%;
  margin: 20px auto;
}

.about-product-info {
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.02em;
  text-align: left;
  color: black;
  width: 100%; /* Adjusted for responsiveness */
  margin-bottom: 20px; /* Added for spacing */
}

.about-product-content {
  display: flex;
  flex-direction: column;
}

.about-product-label {
  font-weight: 600;
  margin-bottom: 10px;
  font-family: Inter;
  font-size: 25px;
  line-height: 38.73px;
  letter-spacing: 0.02em;
  color: #E00006;
}

.product-detail-info {
  flex: 1;
}

.product-detail-text {
  text-align: justify;
  margin-bottom: 20px;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0.02em;
  text-align: left;
  width: 100%; /* Adjusted for responsiveness */
  text-align: justify;
}

.enquiry-button {
  background: linear-gradient(180deg, #E00006 0%, #7A0003 100%);
  font-family: 'Inter';
  font-size: 21px;
  font-weight: 600;
  line-height: 29.05px;
  letter-spacing: 0.02em;
  text-align: center;
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 4px;
  align-self: flex-start;
  text-decoration:none;
}
.enquiry-button:hover {
  background: linear-gradient(180deg, rgb(239, 95, 95) 0%, white 100%);
  color: #E00006;
}

/* Media Queries for responsiveness */
/* @media (max-width: 1024px) {
  .carousel {
    width: 80%;
  }

  .product-info {
    height: auto;
  }

  .product-info img {
    height: auto;
  }
} */

@media (max-width: 768px) {
  .carousel {
    width: 90%;
  }

  .product-detail-section {
    flex-direction: column; /* Change to column on smaller screens */
  }

  .about-product-info {
    width: 100%;
    margin-bottom: 0px;
  }

  .product-detail-text {
    width: 100%;
    font-size: 16px;
    
      line-height: 30px;
      
    }
    .product-info{
      height: 400px;
    }
    .product-info img{
      height: 300px;
    }
    
  
  
}

@media (max-width: 480px) {
  .carousel {
    width: 95%;
  }
  .product-detail-title {
    font-size: 22px;

  }

  .product-description-class {
    font-size: 18px;
  }

  .about-product-label {
    font-size: 22px;
  }

  .enquiry-button {
    font-size: 17px;
  }
  .carousel-icon {
    width: 30px;
    height: 30px;
  }
}




.carousel-icon.prev-icon, .carousel-icon.next-icon {
  opacity: 1;
  transition: opacity 0.3s ease-in-out; 
}

.carousel-icon.prev-icon[style*="opacity: 0"], .carousel-icon.next-icon[style*="opacity: 0"] {
  pointer-events: none; 
  cursor: not-allowed; 
}

.carousel-icon.prev-icon[style*="opacity: 0"]:hover, .carousel-icon.next-icon[style*="opacity: 0"]:hover {
  background-color: #D9D9D9; 
}


@media (min-width: 768px) and (max-width: 1300px) {
  .carousel {
    width: 90%;
  }
  .carousel-icon {
   margin: 30px;
    
  }
  
}
