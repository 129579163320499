.research-dev-wrapper {
  width: 100%;
  margin-top: 100px;
}

.research-dev-image {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.research-dev-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.research-dev-content {
  width: 86%;
  margin: 0 auto;
}

.research-devmain {
  border-left: 3px solid #e10006;
  padding-left: 20px;
}

.research-dev-para {
  font-family: 'Inter';
  font-size: 19px;
  font-weight: 400;
  line-height: 47.58px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #676060;
  margin-bottom: 20px;
  margin-top: 30px;
  text-align: justify;
}

.research-dev-boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.rd-box {
  width: 48%;
  background: white;
 margin-bottom: 40px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.rd-box-heading {
  /* Remove fixed height */
  height: auto;

  /* Center text vertically */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Other styles remain the same */
  font-family: "Inter";
  font-size: 1.2rem;
  font-weight: 900;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(180deg, #e10006 7.02%, #7b0003 100%);
  word-wrap: break-word;
  padding: 15px;
}


.rd-box-content {
  font-family: "Inter";
  font-size: 17px;
  font-weight: 400;
  line-height: 48.6px;
  letter-spacing: 0.02em;
  text-align: justify; /* Add this line */
  color: black;
  padding: 20px;
}


.research-dev-images {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.research-dev-images img {
  width: 600px; /* Adjusted width for desktop */
  height: 495px; /* Adjusted height for desktop */
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .research-dev-image {
    height: auto;
    margin-top: 20%;
  }
  .research-dev-image img {
    width: 100%;
    height: 30%;
    object-fit: cover;
  }

  .research-dev-para {
    font-size: 15px;
    line-height: 30.64px;
    padding-left: 0;
  }

  .research-dev-boxes {
    flex-direction: column;
    align-items: center;
  }

  .rd-box {
    width: 100%;
    margin-bottom: 20px;
  }
  .rd-box-heading{
    font-size: 1rem;
  }
  .rd-box-content {
   
    font-size: 15px;
    font-weight: 400;
    line-height: 35.6px;
  
    color: black;
    padding: 10px;
  }
}
