.footer-container-custom {
    background-color: #880404;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 100px;
    background: linear-gradient(180deg, #BB0106 8.55%, #7A0104 95.34%);

}

.logo-section-custom {
    width: 30%;
    display: flex;
}

.logo-section-custom img {
    width: 60%;
    
}

.links-section-custom {
    width: 50%;
    font-family: Inter;
    /* padding-left: 20px; */
}

.top-links-custom {
    font-size: 18px;
    text-align: left;
    margin-bottom: 10px;
    font-weight: 200;
    line-height: 1.5;
    margin-left: -9px;
    font-family: "Inter" !important;
    font-family: Inter;
font-size: 20px;
font-weight: 700;
line-height: 36.7px;
letter-spacing: 0.02em;
text-align: left;
display: flex;
justify-content: flex-start;


    

}
.top-links-custom a{
    text-decoration: none;
    color: white;
    margin-left: 10px;
    transition: transform 0.3s ease;

}

.top-links-custom .footer-link {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
   
    letter-spacing: 0.02em;
    text-align: left;
    
}


.email-section-custom {
    font-size: 16px;
    text-align: left;
    margin-bottom: 0px;
    
}
.email-section-custom a{
    color: white;
    text-decoration: none;
}

.email-section-custom a:hover{
    background: linear-gradient(rgb(38, 7, 180), rgb(9, 9, 9));
    -webkit-background-clip: text;
    color: transparent;
}


.email-addresses-custom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.email-addresses-custom p {
    margin: 0;
   
    line-height: 1.5;
}


.phone-item-custom:hover .phone-icon {
  animation: shake 0.5s ease;
  cursor: pointer;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-3px); }
  100% { transform: translateX(0); }
}
.phone-item-custom span:hover {
    cursor: pointer;
    
  }

  .ph-no{
    text-decoration: none;
    color: inherit;
  }

.follow-us-custom {
    width: 122px;
    height: 44px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-top: 20px;
}

.follow-us-icons-custom {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    
    
}

.follow-us-icons-custom img {
    width: 35.16px;
    height: 35.09px;
    margin-right: 40px;
    transition: transform 0.3s;
}
.follow-us-icons-custom img:hover {
    transform: rotate(360deg);
}

.address-section-custom {
    width: 35%;
    font-family: 'Inter';
    line-height: 1.5;
}

.addressunit {
    margin-bottom: 20px;
}

.address-section-custom  {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    margin: 0;
    padding-top: 13px;
}

/* Existing CSS ... */

.contact-item-custom {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.contact-icon, .phone-icon {
    margin-right: 10px;
    margin-top: 7px;
}

.contact-text {
    display: flex;
    flex-direction: column;
    font-family: Inter;
font-size: 20px;
font-weight: 500;
line-height: 36.7px;
letter-spacing: 0.02em;
text-align: left;

}

.email-link-custom {
    color: white;
    text-decoration: none;
    font-size: 18px;
   
}

.email-link-custom:hover{
    text-decoration: underline ;
    text-shadow: #7A0104;
}


.phone-item-custom {
    display: flex;
    align-items: center;
}
.phone-item-custom span {
    font-size: 18px;
}


/* ... Existing CSS */


/* Mobile-responsive adjustments */
@media screen and (max-width: 500px) {
    .footer-container-custom {
        flex-direction: column;
       
        padding: 0px;
    }
    .logo-section-custom img {
        width: 30%;
        
    }
   

    .logo-section-custom, .links-section-custom, .address-section-custom {
        width: 100%;
       
        
    }

    .links-section-custom{
        padding: 5%;
    }
    .links-section-custom span{
       
        font-size: 12px;
        text-wrap: wrap;
        color: inherit;
        
        
        
          
        
    }
    .top-links-custom span{
        margin: 0 auto;
        
        
    }
    

    .logo-section-custom img {
       
        margin-top: 50px !important;
     
        margin: 0 auto;
    }

    .follow-us-icons-custom {
        justify-content: space-evenly;
        margin: 0 auto;
    }
    .address-section-custom{
        padding: 15px;
        font-size: 15px;
       padding-left: 20px;
    }
   
    .follow-us-custom {
       width: 100%;
       
        
        font-size:16px;
        display: flex;
        justify-content: center;
       
    }
    .phone-item-custom span{
       font-size: 16px;
    }
    .contact-text {
      
    font-size: 15px;
    
    }
    .email-link-custom {
       
        font-size: 16px;
    }
    .top-links-custom .footer-link {
        
        font-size: 15px;
        
        
    }
    .follow-us-icons-custom img {
      
        margin-right: 0px;
    }
    
    
}


@media (min-width:768px) and (max-width:1240px){
    .footer-container-custom {
       
        padding: 10px 10px;
    }

    .logo-section-custom {
        width:22%;
       
    }
    .logo-section-custom img {
       
    }
    .follow-us-custom {
        width: 100%;
        
         
        
        
     }
     .email-link-custom{
        font-size: 25px;
     }
     .phone-item-custom span {
        font-size: 25px;
    }
     .top-links-custom .footer-link {
        font-size: 25px;
        
    }
     .top-links-custom a{
        font-size: 25px;
        
     }
     .top-links-custom span{
        font-size: 15px;
        margin-top: 30px;
    }
    .address-section-custom {
        font-size: 25px;
        
    }
    .top-links-custom {
    
display: flex;
justify-content: center;


    

}

}


.contact-item-custom {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
   
}

.contact-icon, .phone-icon {
  
    font-size: 30px;
   
}

.phone-item-custom {
    display: flex;
    align-items: center;
}



@media (min-width:500px) and (max-width:1240px) {
    .footer-container-custom {
        display: flex;
        flex-direction: column;
        align-items: center;
       
        background: linear-gradient(180deg, #BB0106 8.55%, #7A0104 95.34%);
        padding: 10px 10px;
    }

    .logo-section-custom {
        width: auto;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        align-items: center;
    }
    .top-links-custom a{
        font: size 1.125rem;;
        
     }

    .links-section-custom {
        width: 100%;
        text-align: center;
    }

    .top-links-custom {
        text-align: center; /* Centering the links */
    }

    .contact-info-custom {
        width: 100%;
        text-align: center;
    }

    .contact-item-custom {
        display: flex;
        align-items: center;
        justify-content: center; /* Centering email and phone */
        margin-bottom: 10px;
    }

    .follow-us-custom {
        font-size: 25px;
        width: 100%;
        margin-top: 30px;
    }

    .address-section-custom {
        width: 100%;
        text-align: center;
    }

    .addressunit {
        text-align: center;
    }

    .unitadd-1-custom,
    .unitadd-2-custom {
        margin-bottom: 20px;
    }
    .phone-item-custom {
        display: inherit;
    }
    .follow-us-icons-custom{
        margin: 0 auto;
        justify-content: space-evenly;
    }
}


@media (min-width:1240px) and (max-width:1400px) {
    .top-links-custom a{
        font-size: 1rem !important;
        
     }
     .footer-container-custom {
padding: 20px 40px;


     }

     .links-section-custom {
        width: 40%;
       
    }

}









