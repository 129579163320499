.whowe4comp-wrapper {
    width: 100%;
}

.whowe4comp-image1 {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.whowe4comp-image1 img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    margin-left: 6.5%;
}

.whowe4comp-image2 {
    width: 100%;
    height: 400px;
    overflow: hidden;
    margin-top: 20px;
}

.whowe4comp-image2 img {
    width: 100%;
    height: 100%;
   
}

.whowe4comp-para1 {
    font-family: "Inter";
    font-size: 19px;
    font-weight: 500;
    line-height: 44.95px;
    letter-spacing: 0.05em;
    text-align: left;
    width: 100%;
    margin: 0 auto;
    background: linear-gradient(180deg, #E10006 0%, #7B0003 100%);
    padding: 1% 7%;
    color: #ffffff;
    text-align: justify;
}

.whowe4comp-heading {
    font-family: Inter;
    font-size: 1.5rem;
    font-weight: 700;
  
    letter-spacing: 0.02em;
    text-align: center;
    color: #E10006;
    margin-top: 3%;
    margin-bottom: 3%;
}

.para3class {
    width: 86%;
    margin: 0 auto;
    border-left: 3px solid #E10006;
}

.whowe4comp-para3 {
    padding-left: 2%;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 37.46px;
    letter-spacing: 0.02em;
    text-align: left;
    color: black;
    margin-bottom: 5%;
}

.whowe4comp-points {
    display: flex;
    flex-wrap: wrap; /* Allow points to wrap to the next line */
    justify-content: space-between; /* Distribute space between points */
    margin-top: 20px;
    width: 80%;
    margin: 0 auto;
    text-align: justify;
}

.point {
    flex: 0 0 calc(50% - 30px);
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Align items at the start vertically */
    margin-bottom: 20px;
    text-align: justify;
}

.icon-container {
    width: 40px;
    height: 40px;
    display: flex; /* Make the container flex to align icon */
    align-items: center; /* Center align icon vertically */
}

.point p {
    font-family: Inter;
    font-size: 17.5px;
    font-weight: 400;
    line-height: 35px; /* Set line-height to match icon container height */
    letter-spacing: 0.02em;
    text-align: left;
    text-align: justify;
}


/* Existing styles remain unchanged */

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .whowe4comp-wrapper {
       margin-top: 5%;
    }
    .whowe4comp-image1 img {
        width: 80%; /* Adjusted width for smaller screens */
        margin-left: 10%; /* Center the image on smaller screens */
    }

    .whowe4comp-image2 {
        height:auto;
    }

    .whowe4comp-para1 {
        font-size: 15px; /* Reduced font size for smaller screens */
        line-height: 35.95px;
    letter-spacing: 0.02em;
    }

    .whowe4comp-heading {
        font-size: 22px; /* Reduced font size for smaller screens */
    }

    .para3class {
        width: 95%; /* Adjusted width for smaller screens */
        border-left: 2px solid #E10006; /* Reduced border size for smaller screens */
    }

    .whowe4comp-para3 {
        font-size: 16px; /* Reduced font size for smaller screens */
    }

    .whowe4comp-points {
        display: block; /* Change to block to ensure one point per row */
        margin-top: 20px;
        width: 95%;
        margin: 0 auto;
    }

    .point {
        width: 95%; /* Take full width */
        margin-bottom: 15px; /* Reduced margin between rows for smaller screens */
        align-items: flex-start; /* Align items to the start for smaller screens */
        text-align: left; /* Text alignment to left for smaller screens */
        margin: 0 auto;
    }

    .point img {
        width: 50px; /* Consistent image size for smaller screens */
        height: auto; /* Maintain aspect ratio */
        margin-right: 5%; /* Adjusted margin for smaller screens */
        float: left; /* Float image to the left */
    }

    .point p {
        font-size: 16px; /* Reduced font size for smaller screens */
        margin-left: 10%; /* Adjusted margin for smaller screens */
        overflow: hidden; /* Ensure text doesn't overlap with the image */
    }
}

/* Existing styles remain unchanged */

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .whowe4comp-points {
        display: block;
        margin-top: 20px;
        width: 95%;
        margin: 0 auto;
    }

    .point {
        width: 100%;
        margin-bottom: 15px;
        text-align: left;
    }

    .point img {
        width: 30px; /* Fixed size for point image */
        height: 30px; /* Fixed size for point image */
        margin-right: 10px;
        float: left;
    }

    .point p {
        font-size: 16px;
        margin-left: 5%;
        overflow: hidden;
    }
}

.icon-wrapper {
    display: inline-block;
    width: 35px; /* Fixed width */
    height: 35px; /* Fixed height */
    margin-right: 10px; 
    vertical-align: middle; 
  }
  
  .icon-wrapper svg {
    width: 100%;
    height: 100%;
    fill: #E10006; 
  }
  

  @media (min-width: 769px) and (max-width: 1400px) {
    .whowe4comp-image1 img {
        width: 70%;
        height: 100%;
        object-fit: cover;
        margin-left: 6.5%;
    }
   
  }
