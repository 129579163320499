.contact3-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
}

.contact3-section {
    display: flex;
   
    margin-bottom: 50px;
}
.contact3-section a{
    text-decoration: none;
    color: inherit;
    font-size: 18px;
}
.contact3-section a:hover{
    color: #e10006;
    text-decoration: underline;
}

.contact3-icon {
    margin-right: 20px;
    font-size: 30px;
   
}


.contact3-email, .contact3-phone {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.02em;
}

.center-section {
    justify-content: center;
}

.follow-us {
    font-family: 'Inter';
    font-size: 21px;
    font-weight: 300;
    line-height: 44.04px;
    letter-spacing: 0.02em;
    text-align: left;
    
}
.follow-us img{
    transition: 0.3s;
}
/* .follow-us img:hover{
    transform: rotate(360deg);
} */

.social-icon {
    width: 40px;
    height: 40px;
    margin-left: 30px;
}
@media screen and (max-width: 450px) {
    .follow-text{
        font-size: 15px;
    }
    .social-icon {
        width: 30px;
      
        margin-left: 20px;
    }
    .contact3-container {
      
        width: 90%;
       
    }
   

}

@media (min-width: 450px) and (max-width: 1200px) {
    .follow-text{
        font-size: 15px;
    }
    .social-icon {
        width: 45px;
        height: 45px;
        margin-left: 20px;
    }
    .contact3-container {
        display: flex;
        justify-content: space-evenly;
        
    }

}


/* Define the keyframes animation */
@keyframes phone-icon-animation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

/* Apply the animation to the phone icon */
.contact3-phone-icon {
    animation: phone-icon-animation 1s infinite;
}
