.navbar-full-width {
  width: 100%;
  padding: 0;
  background: #FFFFFF;
  border-top: 20px solid #E00006;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-image-logo {
  cursor: pointer;
}

.navbar .nav-link {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 400;
  line-height: 39.65px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000; /* Default text color */
  position: relative; /* Required for ::after positioning */
  white-space: nowrap;
}

.navbar .nav-link.active {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 700;
  line-height: 39.65px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #e10006 !important;
  
}
.nav-link.no-underline {
  text-decoration: none !important;
}

.navbar .nav-link.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  text-decoration: none;
}

.navbar .navbar-toggler {
 border: 1px solid black;
}

.navbar-toggler-icon {
  background-color: #ffffff;
}

.custom-dropdown .dropdown-menu {
  border: none; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
  border-radius: 0; 
}

.custom-dropdown .dropdown-item {
  font-family: 'Inter';
  font-size: 17px;
  font-weight: 500;
  line-height: 39.65px;
  letter-spacing: 0.02em;
  text-align: left;
  background-color: #e10006; /* Background color */
  color: #ffffff; /* Text color */
  border-top: 1px solid #fff; /* Border between dropdown items */
}

.custom-dropdown .dropdown-item:hover,
.custom-dropdown .dropdown-item:focus {
  background-color: #cc0000; /* Hover and focus background color */
}

/* Rotate dropdown indicator when dropdown is open */
.navbar .nav-link.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  transition: transform 0.3s ease-in-out; /* Transition for smooth rotation */
}

.navbar .nav-link.dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(180deg); /* Rotate the dropdown indicator when dropdown is open */
}




/* Custom styles for active state of main menu */
.custom-dropdown.active > .nav-link {
  color: #e10006;
}

/* Custom styles for active state of submenu items */
.custom-dropdown .dropdown-item.active {
  background-color: #f7f7f7; 
  color:#e10006; 
 
}


.navbar .navbar-toggler{
  font-size: 30px;
  color: #e10006;
  border: none;
}



.navbar .navbar-toggler {
  font-size: 30px;
  color: #e10006;
  outline: 2px solid transparent; 
  box-shadow: none; 
}

.navbar .navbar-toggler:focus {
  box-shadow:none; 
}

.navbar .navbar-toggler:active {
  box-shadow: none; 
}




/* Default styles for dropdown menu */
.custom-dropdown .dropdown-menu {
  border: none; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
  border-radius: 0; 
  display: none; /* Hide dropdown menu by default */
}

/* Styles for hover effect on desktop and large devices */
@media (min-width: 992px) { /* Adjust breakpoint as per your design */
  .custom-dropdown:hover .dropdown-menu {
    display: block; /* Display dropdown menu on hover */
  }
}


@media (max-width: 500px){
  .navbar .nav-link {
   
    font-size: 16px;
   
  }
  .custom-dropdown .dropdown-item {
    font-family: 'Inter';
    font-size: 15px;
    
  }
  

}




