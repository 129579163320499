.whowe1comp-wrapper {
  width: 100%;
  margin-top: 100px;
}

.whowe1comp-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 83%;
  margin: 0 auto;
}

.whowe1comp-vision {
  width: 55%;
}

.whowe1comp-vision-image {
  position: relative;
  width: 45%;
  margin-top: 5%;
}

.graybg {
  position: absolute;
  top: 0;
  right: 0; 
  width: 70%; 
  height: 130%; 
  background-color: #eeeeee;
  z-index: 1;
}


.half-in-half-out {
  position: relative;
  z-index: 2;
  width: 70%;
  height: auto;
  top: 40px;
  margin-left: 8%; /* Move the image half outside the box */
}

.vision-heading {
  font-family: Moul;
  font-size: 30px;
  font-weight: 400;
  line-height: 72.27px;
  letter-spacing: 0.14em;
  text-align: left;
  width: 100%;
  height: 72px;
  position: relative; /* Make the heading container relative */
  margin-bottom: 20px;
  color: #e10006;
}

.vision-heading::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust this value to control the gap between the text and underline */
  width: 42%;
  height: 2px; /* Adjust this value to control the thickness of the underline */
  background-color: #000000; /* Color of the underline */
}



.vision-points {
  list-style-type: none;
  padding-left: 0;
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Arrange list items vertically */
  text-align: justify;
}

.vision-points li {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.02em;
  text-align: left;
  position: relative;
  margin-bottom: 15px;
  padding-left: 20px;
  display: flex; /* Use flexbox for layout */
  text-align: justify;
}

.vision-points li::before {
  content: '●';
  color: #000000;
  font-size: 24px;
  margin-right: 10px; /* Add margin between bullet and text */
  text-align: justify;
}


.whowe1comp-banner {
  width: 100%;
  height: auto;
  overflow: hidden;
 
}

.whowe1comp-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Mobile Responsive Styles */
/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .whowe1comp-wrapper{
    
  }
  .whowe1comp-content {
    flex-direction: column;
   
  }
  .vision-points li {
   
    padding-left:0px;
   
  }

  .whowe1comp-vision,
  .whowe1comp-vision-image {
    width: 100%;
  }
  .vision-heading{
    font-size: 30px;
  }

  .graybg {
    width: 70%; /* Changed to 100% */
    height: 100%; /* Changed to 100% */
  }

  .full-height {
    width: 100%; /* Changed to 100% */
    height: auto; /* Changed to auto */
    object-fit: contain; /* Changed to contain for better fit on mobile */
  }

  .half-in-half-out {
    position: relative;
    z-index: 2;
    width: 79%;
    height: auto;
    top: 40px;
   
  }

  .whowe1comp-banner {

  }

  .whowe1comp-banner img {
    width: 100%;
 
    object-fit: contain;
  }

  .vision-heading {
    text-align: center;
  }
  .vision-heading::after {
    
    width: 100%;
    
  }

 
}

@media (min-width: 769px) and (max-width: 1024px) {
  .graybg {
    width: 70%; /* Changed to 100% */
    height: 160%; /* Changed to 100% */
  }

  .full-height {
    width: 100%; /* Changed to 100% */
    height: auto; /* Changed to auto */
    object-fit: contain; /* Changed to contain for better fit on mobile */
  }

  .half-in-half-out {
    position: relative;
    z-index: 2;
    width: 79%;
    height: auto;
    top: 50px;
   
  }
}