.whowe2comp-wrapper {
  width: 100%;
}

.whowe2comp-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  width: 83%;
  margin: 0 auto;
}

.whowe2comp-mission-image {
  flex: 0 0 45%;
  max-width: 45%;
  height: 350px;
  position: relative;
}

.gray-bg-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%; /* Adjust the width as per your requirement */
  height: 90%;
  background-color: #f0f0f0; /* Gray background color */
  z-index: -1; /* Place it behind the image */
}

.whowe2comp-mission-image img {
  position: absolute;
  top: 45%;
  right: 0;
  transform: translateY(-50%);
  width: 80%;
  height: auto;
  object-fit: cover;
  z-index: 1;
}

.whowe2comp-mission {
  flex: 0 0 45%;
  max-width: 45%;
  margin-top: 20px;
}

.mission-heading {
  font-family: Moul;
  font-size: 30px;
  font-weight: 400;
  line-height: 72.27px;
  letter-spacing: 0.14em;
  text-align: left;
 width: 65%;
  height: 72px;
  border-bottom: 2px solid #020202;
  margin-bottom: 20px;
  color: #e10006;
}

.mission-points {
  list-style-type: none;
  padding-left: 0;
  text-align: justify;
}

.mission-points li {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.02em;
  text-align: left;
  position: relative;
  margin-bottom: 15px;
  text-align: justify;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .whowe2comp-wrapper {
    margin-top: 10%;
  }  
  .whowe2comp-content {
    flex-direction: column-reverse; /* Reverse the order for mobile */
    align-items: center;
  }

  .whowe2comp-mission-image,
  .whowe2comp-mission {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-top: 20px; /* Add margin to separate the two sections */
  }

  .whowe2comp-mission-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .mission-heading {
    text-align: center;
    font-size: 30px;
    width: 100%;
  }

 
  
  
  .whowe2comp-mission-image img {
    width: 80%;
    position: relative;
    left: 7%;     /* Resets the left property */
    right: 0;       /* Aligns the image to the right */
    height:150px;
    top: 40px;         /* Resets the top property */
    transform: translateY(0); /* Resets the transform property */
    object-fit: cover;
  }
  
  
  .gray-bg-box {
    position: absolute;
    top: 0;
    bottom: 20px;
    left: 30%; /* Align to the left */
    width: 70%; /* Adjust the width as per your requirement */
    height: 100%;
   
    z-index: -1; /* Place it behind the image */
}

}

@media (min-width: 767px) and (max-width: 1400px) {

  .mission-heading {
    font-family: Moul;
    font-size: 30px;
    font-weight: 400;
    line-height: 72.27px;
    letter-spacing: 0.14em;
    text-align: left;
   width: 100%;
    height: 72px;
    border-bottom: 2px solid #020202;
    margin-bottom: 20px;
    color: #e10006;
  }}