.contact-us-container {
    width: 100%;
    margin: 0 auto;
  
   border-bottom: 24px solid #eeeeee;
   overflow: hidden;
}

.banner-image {
    width: 100%;
}

.contact-sections {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    width: 90%;
    margin: 0 auto;
}

/* Left Section */
.contact-info-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-info-text-main {
    border-left: 3px solid #E00006;
}

.contact-info-heading {
    font-family: 'Inter';
    font-size: 28px;
    font-weight: 500;
    line-height: 38.73px;
    letter-spacing: 0.02em;
    text-align: left;
    color: black;
    margin-bottom: 20px;
}

.contact-info-text {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #676060;
    padding-left: 5%;
}

/* Right Section */
.contact-input-section {
    /* flex: 1; */
    padding-left: 50px;
}

.contact-input,
.contact-textarea {
    width: 100%;
    padding: 16px 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #676060;
}

.contact-textarea {
    height: 100px;
}

.contact-submit {
    padding: 0.8rem 10rem;
    min-width: 10rem; /* minimum width */
    background-color: #E00006;
    border: 1px solid #676060;
    border-radius: 4px;
    font-family: 'Inter';
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-align: center;
    color: white;
    opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
    margin: 0 auto;
   
    
}

.contact-submit:hover{
    background-color: white;
    color: #E00006;
    border: 1px black solid;
}


/* Responsive styles */
@media (max-width: 1024px) {
    .contact-sections {
        flex-direction: column;
    }

    .contact-input-section {
        padding-left: 0;
        padding-top: 30px;
    }
}

@media (max-width: 768px) {
    .contact-us-container {
        
       
    }
    .contact-sections {
        padding: 30px;
    }

    .contact-info-heading {
        font-size: 28px;
    }

    .contact-info-text {
        font-size: 18px;
    }

    .contact-input,
    .contact-textarea {
        padding: 14px 8px;
    }
}

@media (max-width: 576px) {
    .contact-info-heading {
        font-size: 24px;
    }

    .contact-info-text {
        font-size: 16px;
    }

    .contact-input,
    .contact-textarea {
        padding: 12px 6px;
    }

    .contact-submit {
        padding: 12px 37%;
       
    }
    .contact-sections {
      
        width: 100%;
        
    }
}

@media (min-width: 800px) {
    .contact-us-container {
        
       
    }
}

