.busduct-wrapper{
    margin-top: 120px;
    margin-bottom: 5%;
}

.Busdust-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(407px, 1fr));
    gap: 20px; /* Space between product-boxes */
    justify-content: center; /* Center align the items horizontally */
    align-items: center; /* Center align the items vertically */
  }
  
  .busdust-box {
 margin: 0 auto;
  }
  